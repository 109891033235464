export const SERV_PATH = getVar('VUE_APP_SERV_PATH');
export const DEFAULT_LANGUAGE = getVar('VUE_APP_DEFAULT_LANGUAGE');
export const HELP_LINK = getVar('VUE_APP_HELP_LINK');

const WINDOW_HREF = window.location;
const REQUEST_ID_POSITION_IN_URL = 1;
export const REQUEST_ID = WINDOW_HREF.pathname.split('/')[REQUEST_ID_POSITION_IN_URL];
export const AUTH_PATH = `${SERV_PATH}api/auth/${REQUEST_ID}/`;
export const SOCKET_URL = `${SERV_PATH}broadcastHub`;

export const VALIDATION_TYPE_TELEGRAM = 'Telegram';
export const VALIDATION_TYPE_WEBAUTHN = 'WebAuthn';
export const VALIDATION_TYPE_HOTPTOKEN = 'HotpToken';
export const VALIDATION_TYPE_TOTPTOKEN = 'TotpToken';
export const VALIDATION_TYPE_SMS = 'SMS';
export const VALIDATION_TYPE_APP = 'MobileApp';
export const VALIDATION_METHOD_ONLINE = 'OnlineRequest';
export const VALIDATION_METHOD_ONLINE_WITH_OTP = 'OnlineRequestWithOtp';
export const VALIDATION_METHOD_OFFLINE = 'OfflineOTP';

function getVar(name) {
    if (!process.env[name]) throw new Error(`Environment variable '${name}' is required`);
    return process.env[name];
}