import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { REQUEST_ID, SOCKET_URL } from "../config.js";
import Store from "../store/index.js";
import Router from "@/services/router";
import Vue from 'vue';

const sessionKey = 'mf_session';

export const CONNECTION = new HubConnectionBuilder()
  .withUrl(SOCKET_URL)
  .withAutomaticReconnect([0, 1000])
  .configureLogging(LogLevel.Warning)
  .build();

const CONNECT_FUNC = () => {
  const id = REQUEST_ID;
  if (id) {
    CONNECTION.invoke("Register", id).then(() => {
      Store.dispatch('SET_CONNECTION_READY', true);
    }).catch(err => {
      console.error(err.toString());
    });
  } else {
    console.error('REQUEST_ID is empty');
  }
}

export default {
  install() {
    
    CONNECTION.onreconnected(() => {
      CONNECT_FUNC();
    });
    
    CONNECTION.start().then(() => {
      CONNECT_FUNC();
    })
    .catch(err => {
      console.error(err.toString());
    });

    CONNECTION.on("AccessGranted", message => {

      if (message.session) {
        Vue.$cookies.isKey(sessionKey) && Vue.$cookies.remove(sessionKey);
        Vue.$cookies.set(sessionKey, message.session, '1d', '', '', true, 'None', true);
      }

      if(Store.getters.GET_CLICKED_LINK) {
        document.location.href = `${process.env.VUE_APP_ENROLL_PATH || ''}/enroll/${Store.getters.GET_REQUEST_ID}`;
      } else {
        Store.dispatch('SET_HIDDEN_FORM', message);
      }
    });

    CONNECTION.on("AccessDenied", () => {
      Router.push({
        name: "401",
        params: {
          errCode: "401"
        }
      });
    });
    
    CONNECTION.on("SendMessage", () => {
      //
    });
  }
};
