import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from "../store";

Vue.use(VueRouter);

function load(component) {
  return () => import(`@/pages/${component}.vue`);
}

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { 
      path: '/:reqId?/contacts', 
      name: 'Contacts', 
      component: load('ContactsPage'), 
    },
    { 
      path: '/401', 
      name: '401', 
      component: load('ErrorPage'), 
    },
    { 
      path: '/410', 
      name: '410', 
      component: load('ErrorPage'), 
    },
    { 
      path: '/404', 
      name: '404', 
      component: load('ErrorPage'), 
    },
    { 
      path: '/:reqId', 
      name: 'Home', 
      component: load('AuthorizationPage'), 
    },
    {
      path: '/:reqId/*',
      redirect: '/:reqId',
    },
    {
      path: '/*',
      redirect: '/:reqId',
    },
  ]
});

const matchedRoutes = [ 'Home', 'Contacts' ];

router.beforeEach(async (to, from, next) => {
  await Vue.nextTick();
  
  if (matchedRoutes.includes(to.name)) {
    const reqId = to.params['reqId'];
    if (reqId && router.app.$store.getters.GET_REQUEST_ID !== reqId) {
      router.app.$store.commit('MUTATE_REQUEST_ID', reqId);
    }
  }

  await Store.getters.GET_WEBSOCKET_STATE_AWAITABLE;

  if (to.name === 'Home') {
    to.query['cl'] && to.query['cl'] === 'true' && router.app.$store.commit('MUTATE_CLICKED_LINK', true);
  }

  next();
  
});

export default router;