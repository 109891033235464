import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from '@/services/router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Hub from './services/hub.js';
import i18n from '../config/i18n.js'; // lang
import ElementUI from 'element-ui'; // ElementUI
import locale from 'element-ui/lib/locale/lang/en'; // eng locale for Element UI
import 'element-ui/lib/theme-chalk/index.css'; 
import './assets/scss/chanel.scss'; // import styles
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false
Vue.use(ElementUI, { locale });

// Axios
Vue.use(VueAxios, axios);
Vue.axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  if(error.response) {
    switch (error.response.status) {
      case 401:
        router.push({
          name: "401",
          params: {
            errCode: "401"
          }
        });
        break;
      case 404:
        router.push({
          name: "404",
          params: {
            errCode: "404"
          }
        });
        break;
      case 410:
        router.push({
          name: "410",
          params: {
            errCode: "410"
          }
        });
        break;
    
      default:
        router.push('404');
    }
  } else {
    router.push('404');
  }
  return Promise.reject(error)
})

// vue-socket.io
Vue.use(Hub);
Vue.use(VueCookies)

new Vue({
  store,
  router,
  i18n,
  data: () => ({ n: 0 }),
  render: h => h(App),
}).$mount('#app')
