import axios from 'axios';
import store from '../store/index.js';

import {
  AUTH_PATH,
  VALIDATION_TYPE_TELEGRAM,
  VALIDATION_TYPE_WEBAUTHN,
  VALIDATION_TYPE_SMS,
  VALIDATION_TYPE_APP,
 } from '../config.js';

export default {
  getLogo() {
    return axios.get(`${AUTH_PATH}logo`, {});
  },
  
  getAuthTypes() {
    return axios.get(`${AUTH_PATH}providers?lang=${store.getters.GET_CURR_LOCALE}`, {});
  },

  // Telegram
  getTelegramTypes() {
      return axios.get(`${AUTH_PATH}${VALIDATION_TYPE_TELEGRAM}/items?lang=${store.getters.GET_CURR_LOCALE}`, {
    });
  },

  // WebAuth
  getWebAuthTypes() {
      return axios.get(`${AUTH_PATH}${VALIDATION_TYPE_WEBAUTHN}/items?lang=${store.getters.GET_CURR_LOCALE}`, {
    });
  },
  getWebAuthResponse() {
    return axios.get(`${AUTH_PATH}webauthn?lang=${store.getters.GET_CURR_LOCALE}`, {});
  },

  // SMS
  getSmsTypes() {
      return axios.get(`${AUTH_PATH}${VALIDATION_TYPE_SMS}/items?lang=${store.getters.GET_CURR_LOCALE}`, {
    });
  },
  postSms(id) {
    return axios.post(`${AUTH_PATH}sms?id=${id}`, {});
  },

  // APP
  getAppTypes() {
      return axios.get(`${AUTH_PATH}${VALIDATION_TYPE_APP}/items?lang=${store.getters.GET_CURR_LOCALE}`, {
    });
  },

  // Contacts
  getContacts() {
    return axios.get(`${AUTH_PATH}contact?lang=${store.getters.GET_CURR_LOCALE}`, {});
  },
}